/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('./fonts/montserrat-v25-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./fonts/montserrat-v25-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('./fonts/montserrat-v25-latin-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./fonts/montserrat-v25-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('./fonts/montserrat-v25-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./fonts/montserrat-v25-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

li {
  cursor: pointer;
}

ul {
  list-style-type: none;
}

html {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  background-color: rgb(255, 255, 255);
}

.container {
  margin: 0 32px;
}

.row {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
}

.floater {
  color: #0a66c2;
  background-color: white;
  font-size: 1.5rem;
  padding: 15px;
  border-radius: 100%;
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 300ms padding;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  animation: move-up-down 2s ease-in-out infinite alternate;
}

@keyframes move-up-down {
  0% {
    transform: translateY(calc(-50%)) translateX(-50%);
  }
  100% {
    transform: translateY(calc(-50% - 20px)) translateX(-50%);
  }
}

.floater-bg {
  background-color: rgb(123, 223, 121);
}

.floater:hover {
  padding: 35px;
}

.app--loader {
  position: absolute;
  /* background-color: white; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shimmer {
  margin-bottom: 12px;
  width: 100%;
  height: 20px;
  border-radius: 4px;
  background: #cacaca;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );

  animation: placeholderShimmer 0.5s forwards infinite linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.shimmer-medium {
  height: 50px;
}

.shimmer-large {
  height: 200px;
}

/* 

DARK-MODE

*/

body.dark {
  background-color: #202023;
}

body.dark .app--loader {
  background-color: #111;
}

body.dark .home__title,
body.dark .projects__title,
body.dark .resume__title,
body.dark .home__description,
body.dark .about__para,
body.dark .about__title--tech,
body.dark .about__title,
body.dark .pdfDownloadBtn,
body.dark .contact__title,
body.dark .contact__para--description,
body.dark .contact__email,
body.dark .downloadBtn-arrow {
  color: white;
}

body.dark .background-image {
  filter: brightness(0.7);
}

/* 

NAV

*/

.App {
}

.nav__container {
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  image-rendering: pixelated;
  z-index: 999;
}

nav {
  width: 100%;
  margin: 0 auto;
  max-width: 1800px;

  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.nav__logo {
  height: 60px;
  width: 65px;
  user-select: none;
}

.nav__link--left {
  cursor: pointer;
  margin-left: 10px;
  font-size: 1.7rem;
  transition: transform 300ms;
}

.nav__link--left:hover {
  transform: scale(1.2);
}

.nav__link--left:active {
  transform: scale(0.8);
}

.nav__links {
  display: flex;
  align-items: center;
  font-size: 18px;
  z-index: 0;
  user-select: none;
}

.nav__link {
  color: white;
  position: relative;
  padding: 8px 10px;
  margin: 8px;
  text-decoration: none;
}

.nav__link:hover {
  color: #33b9de;
}

.nav__link.active > p {
  color: white;
}

.selected {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #14809c;
  filter: contrast(2);
  /* background-color: #33b9de; */
  border-radius: 12px;
  z-index: -1;
}

.switchInput {
  font-size: 12px;
}

/* 

Modal

*/

.modal__button {
  display: none;
  margin-left: 16px;
}

/* 

Home

*/

.home__container {
  overflow: hidden;
  position: relative;
  min-width: 100vw;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image--wrapper {
  position: absolute;
  top: -100px;
  left: -200px;
  right: -200px;
  bottom: -100px;
}

.background-image {
  filter: contrast(1.1);
  width: 100%;
  height: 100%;
  object-fit: cover;

  z-index: -1;
}

.home__info--wrapper {
  z-index: 100;
}

.home__title {
  font-weight: 500;
  font-size: 30px;
  color: #111;
}

.home__description {
  font-weight: 500;
  font-size: 72px;
  color: #111;
}

.projects__title,
.resume__title {
  padding-top: 200px;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 80px;
  color: #111;
  overflow: hidden;
}

.projects__title {
  text-align: center;
}

.secret {
  position: absolute;
  height: 3px;
  width: 3px;
  top: 80px;
  left: 0;
}

/* 

About

*/

.about__title {
  padding-top: 200px;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 80px;
  color: #111;
  overflow: hidden;
}

.about__para {
  font-size: 1.6rem;
  line-height: 2;
}

.about__title--tech {
  font-size: 60px;
  font-weight: 500;
  margin: 150px;
  white-space: nowrap;
}

/* language box */

.language__container {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  gap: 100px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  justify-items: center;
}

.language__image {
  position: absolute;
  width: 50px;
  height: 50px;
}

.language {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  overflow: hidden;
}

.language__title {
  position: absolute;
  font-size: 16px;
}

/* 

Projects

*/

.projects {
  position: relative;
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr;
}

.project {
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  display: flex;
  min-height: 619px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.project:hover .project__img {
  transform: scale(1.07) !important;
}

.project__img--wrapper {
  position: relative;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  width: 50%;
}

.project__img {
  transition: transform 300ms ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.project__languages--wrapper {
  pointer-events: none;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  z-index: 1;
  position: absolute;
  bottom: 4px;
  left: 0;
  height: 30px;
  width: 100%;
  padding: 0 8px;
}

.project__language {
  font-size: 0.8rem;
  background-color: #111;
  color: white;
  padding: 10px;
  border-radius: 50px;
}

.project__info {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  width: 50%;
}

.project--viewport-trigger {
  position: absolute;
  top: 0;
  right: 0;
  height: 3px;
  width: 3px;
}

.project-buttons--wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.projectsBtn {
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  text-decoration: none;
  background-color: #33b9de;
  font-size: 1.3rem;
  padding: 8px 16px;
  border-radius: 8px;
  /* min-width: 129.22px; */
  text-align: center;
}

.projectsBtn--black {
  /* grid-column: 2 / 3; */
  background-color: rgb(51, 51, 51);
  /* min-width: 163.63px; */
}

.bg--gray {
  background-color: rgb(14, 57, 72);
}

.projectsBtn--gray {
  grid-row: 1;
  grid-column: 1 / 3;
  background-color: rgb(14, 57, 72);
  /* min-width: 163.63px; */
}
projectsBtn--black .project__title {
  font-size: 48px;
  font-weight: 500;
}

.project__para {
  white-space: pre-line;
  text-align: justify;
  line-height: 1.75;
  margin: 24px 0;
}

.project__more-info--bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(68, 68, 68, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project__more-info--wrapper {
  position: relative;
  border-radius: 20px;
  width: 100%;
  max-width: 1300px;
  height: 90vh;
  margin: 0 64px;
  padding: 64px 22px 32px 32px;
  background-color: white;
}

.project__more-info {
  height: calc(90vh - 64px * 2);
  overflow: hidden;
  overflow-y: auto;
}

.project__more-info > div {
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project__more-info ul {
  list-style-type: disc;
  list-style-position: inside;
}

.project__more-info img {
  max-width: 100%;
}

.closeBtn--more-info {
  cursor: pointer;
  font-size: 2.5rem;
  position: absolute;
  top: -16px;
  right: -16px;
  background-color: rgb(212, 212, 212);
  padding: 4px;
  border-radius: 50%;
}

/*

Resume

*/

.resume__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pdf--wrapper {
  /* background-color: white; */
  width: 100%;
  aspect-ratio: 0.863;
  margin: 0 auto;
  max-width: 1300px;
}

.pdfDownloadBtn {
  text-decoration: none;
  color: black;
  font-size: 1.7rem;
  padding: 8px 12px;
  border-radius: 12px;
  transition-duration: 0.3s;

  display: inline-flex;
  align-items: center;
  margin: 8px;
}

.downloadBtn-arrow {
  color: black;
  margin-left: 8px;
  transition-duration: 0.3s;
}

.pdfDownloadBtn:hover {
  color: white;
  background-color: #33b9de;
  box-shadow: 0 4px 6px hsla(0, 0%, 0%, 0.2);
}

.pdfDownloadBtn:active {
  filter: brightness(0.9);
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.2);
}

.pdfDownloadBtn:hover .downloadBtn-arrow {
  color: white;
  transform: rotate(90deg);
}

.parallax-background-image--wrapper {
  position: absolute;
  top: -100px;
  left: -200px;
  right: -200px;
  bottom: -100px;
}

.parallax-background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  z-index: -1;
}

.parallax__info--wrapper {
  z-index: 100;
}

.rpv-core__inner-page {
  background-color: transparent !important;
}

/*

Modal

*/

.modal__container {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(10px);

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.modal__links {
  display: flex;
  flex-direction: column;
}

.modal__link {
  cursor: pointer;
  text-decoration: none;
  margin-top: 32px;
  color: white;
  font-size: 32px;
}

/*

Contact

*/

.contact__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact__title {
  padding-top: 100px;
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 80px;
  color: #111;
  overflow: hidden;
}

.contact__para--description {
  margin-bottom: 100px;
  max-width: 460px;
  line-height: 1.5;
}

.contact-form {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 80px;
  max-width: 550px;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
  margin-bottom: 100px;
}

.contact__label {
  color: black;
  font-weight: 500;
  width: 100%;
}

.contact__input {
  line-height: 1.5;
}

.contact__input,
.contact__textarea {
  border: 2px solid #a4a4a4;
  outline: none;
  border-radius: 4px;
  padding: 10px 4px 10px 40px;
  width: 100%;
}

.contact__textarea {
  resize: none;
  min-height: 200px;
}

.contact__input:focus,
.contact__textarea:focus {
  background-color: #f9f9fb;
  border: 2px solid rgb(51, 185, 222);
}

.contact__error-message {
  margin-bottom: 4px;
  width: 100%;
  font-size: 0.9rem;
  color: red;
}

.contact__icon {
  color: #7a7a7a;
}

.contact-input--wrapper {
  width: 100%;
  position: relative;
}

.contact__input:focus + .contact__icon,
.contact__textarea:focus + .contact__icon {
  color: rgb(51, 185, 222);
}

.contact__icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.contact__icon--textarea {
  top: 20px;
}

.submitBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #14809c;
  filter: contrast(2);
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  min-width: 73.34px;
  box-shadow: 0 4px 6px hsla(0, 0%, 0%, 0.2);
  transition: box-shadow 200ms;
}

.submitBtn:hover {
  filter: brightness(0.9);
}

.submitBtn:active {
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.2);
}

.toast {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 20px;
  z-index: 1000;
  background-color: white;
  width: 200px;
  height: 50px;
  border-radius: 8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

/* 

Footer

*/

footer {
  background-color: black;
  padding: 36px 72px;
}

.footer--top {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.footer__links {
  color: white;
}

.footer__link {
  padding: 4px;
  margin: 12px;
}

.footer__link--anchor {
  color: white;
  text-decoration: none;
}

.footer__description {
  text-align: center;
  padding-top: 2.5rem;
  color: white;
}

.footer__logo {
  height: 60px;
  width: 65px;
  margin-right: 5px;
}

.footer__logo--popper {
  position: absolute;
  top: 25px;
  left: 40px;
  color: white;
  font-weight: 500;
  opacity: 0;
  transition: all 350ms ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__figure {
  margin-top: 12px;
  cursor: pointer;
  width: 75px;
  height: 60px;
  position: relative;
  transition: transform 300ms ease;
}

.footer__figure:hover {
  transform: scale(1.1);
}

.footer__figure:hover .footer__logo--popper {
  transform: translateX(16px);
  opacity: 1;
}

/* LOADER */
.chaotic-orbit {
  --uib-size: 16px;
  --uib-speed: 1.5s;
  --uib-color: white;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--uib-size);
  width: var(--uib-size);
  animation: rotate calc(var(--uib-speed) * 1.667) infinite linear;
}

.chaotic-orbit::before,
.chaotic-orbit::after {
  content: '';
  position: absolute;
  height: 60%;
  width: 60%;
  border-radius: 50%;
  background-color: var(--uib-color);
  will-change: transform;
  flex-shrink: 0;
}

.chaotic-orbit::before {
  animation: orbit var(--uib-speed) linear infinite;
}

.chaotic-orbit::after {
  animation: orbit var(--uib-speed) linear calc(var(--uib-speed) / -2) infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes orbit {
  0% {
    transform: translate(calc(var(--uib-size) * 0.5)) scale(0.73684);
    opacity: 0.65;
  }
  5% {
    transform: translate(calc(var(--uib-size) * 0.4)) scale(0.684208);
    opacity: 0.58;
  }
  10% {
    transform: translate(calc(var(--uib-size) * 0.3)) scale(0.631576);
    opacity: 0.51;
  }
  15% {
    transform: translate(calc(var(--uib-size) * 0.2)) scale(0.578944);
    opacity: 0.44;
  }
  20% {
    transform: translate(calc(var(--uib-size) * 0.1)) scale(0.526312);
    opacity: 0.37;
  }
  25% {
    transform: translate(0%) scale(0.47368);
    opacity: 0.3;
  }
  30% {
    transform: translate(calc(var(--uib-size) * -0.1)) scale(0.526312);
    opacity: 0.37;
  }
  35% {
    transform: translate(calc(var(--uib-size) * -0.2)) scale(0.578944);
    opacity: 0.44;
  }
  40% {
    transform: translate(calc(var(--uib-size) * -0.3)) scale(0.631576);
    opacity: 0.51;
  }
  45% {
    transform: translate(calc(var(--uib-size) * -0.4)) scale(0.684208);
    opacity: 0.58;
  }
  50% {
    transform: translate(calc(var(--uib-size) * -0.5)) scale(0.73684);
    opacity: 0.65;
  }
  55% {
    transform: translate(calc(var(--uib-size) * -0.4)) scale(0.789472);
    opacity: 0.72;
  }
  60% {
    transform: translate(calc(var(--uib-size) * -0.3)) scale(0.842104);
    opacity: 0.79;
  }
  65% {
    transform: translate(calc(var(--uib-size) * -0.2)) scale(0.894736);
    opacity: 0.86;
  }
  70% {
    transform: translate(calc(var(--uib-size) * -0.1)) scale(0.947368);
    opacity: 0.93;
  }
  75% {
    transform: translate(0%) scale(1);
    opacity: 1;
  }
  80% {
    transform: translate(calc(var(--uib-size) * 0.1)) scale(0.947368);
    opacity: 0.93;
  }
  85% {
    transform: translate(calc(var(--uib-size) * 0.2)) scale(0.894736);
    opacity: 0.86;
  }
  90% {
    transform: translate(calc(var(--uib-size) * 0.3)) scale(0.842104);
    opacity: 0.79;
  }
  95% {
    transform: translate(calc(var(--uib-size) * 0.4)) scale(0.789472);
    opacity: 0.72;
  }
  100% {
    transform: translate(calc(var(--uib-size) * 0.5)) scale(0.73684);
    opacity: 0.65;
  }
}

/* 

MEDIA QUERIES 

*/

@media (max-width: 1700px) {
  .projects {
    grid-template-columns: 1fr;
    gap: 0px;
    row-gap: 100px;
  }
  .project__info {
    width: 30%;
  }
  .project__img--wrapper {
    width: 70%;
  }
}

@media (max-width: 1400px) {
  .project-buttons--wrapper {
    flex-direction: column;
  }
}

@media (max-width: 900px) {
  .language__container {
    gap: 50px;
  }
  .nav__link {
    display: none;
  }
  .modal__button {
    display: block;
  }
  .home__description {
    inline-size: min-content;
    font-size: 60px;
  }
  .project {
    flex-direction: column;
  }

  .project__img--wrapper {
    height: 500px;
  }
  .project__img--wrapper,
  .project__info {
    width: 100%;
  }
  .project-buttons--wrapper {
    flex-direction: row;
  }
}

@media (max-width: 500px) {
  .about__para {
    font-size: 1.4rem;
  }
  .about__title--tech {
    font-size: 40px;
  }

  .about__title,
  .projects__title,
  .resume__title,
  .contact__title {
    font-size: 60px;
  }

  .about__title,
  .projects__title,
  .resume__title {
    margin-bottom: 40px;
  }

  .project__title {
    font-size: 34px;
  }

  .contact__para--description {
    text-align: center;
  }
  .contact-form {
    padding: 40px;
  }
  .project-buttons--wrapper {
    flex-direction: column;
  }
  .shimmer {
    animation: placeholderShimmer 3s forwards infinite linear;
  }
  .project__more-info--wrapper {
    min-width: 85%;
  }
}
